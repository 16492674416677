/* globals jQuery, wordpress_globals */

import "../sass/theme.scss";

// import $ from 'jquery';
import svgxhr from "webpack-svgstore-plugin/src/helpers/svgxhr";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import MicroModal from "micromodal";
//
// // Remove NinjaForms tags like <nf-field>
// (function ($) {
//   $(window).load(function () {
//     setTimeout(() => {
//       $('.nf-form-cont').find("nf-field").contents().unwrap();
//       $('.nf-form-cont').find("nf-fields-wrap").contents().unwrap();
//       $('.nf-form-cont').find("nf-section").contents().unwrap();
//       $('.nf-form-cont').find("nf-errors").contents().unwrap();
//       $('.nf-form-cont').addClass("fields-fixed");
//     }, 100);
//   });
// })(jQuery);

const moduleDefinition = [
  { selector: ".card-slider", moduleName: "CardSlider" },
  { selector: ".location-swiper", moduleName: "LocationSwiper" },
  { selector: ".testimonial-slider", moduleName: "TestimonialSlider" },
  { selector: ".stats-slider", moduleName: "StatSlider" },
  { selector: ".people-slider", moduleName: "StatSlider" },
  { selector: ".reviews-swiper .swiper-container", moduleName: "ReviewsSwiper" },
  { selector: ".person, .person-link", moduleName: "Person" },
  { selector: ".accordion", moduleName: "Accordion" },
  { selector: ".location-map", moduleName: "LocationMap" },
  { selector: ".content-card-slider", moduleName: "ContentCardSlider" },
  { selector: ".image-slider", moduleName: "ImageSlider" },
];

window.namespace = {
  init: function () {
    this.helpers.initSvg();
    this.cssVars.init();

    MicroModal.init({
      onShow: () => {
        window.document.documentElement.classList.add("modal-open");
      },
      onClose: () => {
        window.document.documentElement.classList.remove("modal-open");
      },
      openClass: 'is-open',
      disableScroll: true,
      disableFocus: false,
      awaitOpenAnimation: false,
      awaitCloseAnimation: false,
    });

    (moduleDefinition ?? []).forEach(({ selector, moduleName }) => {
      (document.querySelectorAll(selector) ?? []).forEach((el) => this.modules.loader(el, moduleName));
    });

    if (document.querySelector('#menu-primary-navigation') !== null) {
      document.querySelectorAll('#menu-primary-navigation').forEach((el) => {
        el.desktopNav = this.modules.loader(el, "SiteMenu");
        el.mobileNav = this.modules.loader(el, "MobileNavigation");
      });
    }

    setTimeout(() => {
      import(`./modules/Animations`).then(({ default: Module }) => {
        Module.init();
      })
    }, 100);

    setTimeout(() => {
      if (document.querySelector('.review') !== null) {
        document.querySelectorAll('.review').forEach((el) => this.modules.loader(el, "LineClamp"));
      }
    }, 10);

    setTimeout(this.sra.init.bind(this), 1000);

    //Allow user to interrupt auto-scrolling
    $(document).bind('scroll mousedown wheel keyup', function (e) {
      if (e.which || e.type === 'mousedown' || e.type === 'mousewheel') {
        $(document.scrollingElement).stop();
      }
    });
  },
  modules: {
    loader: (el, moduleName) => {
      try {
        return import(`./modules/${moduleName}`).then((mod) => {
          const Module = mod.default;
          return new Module(el);
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(error);
      }

      return false;
    },
  },
  cssVars: {
    init: function() {
      this.setVars();
      window.addEventListener("resize", this.setVars.bind(this));
      window.addEventListener("nav.opened", this.setVars.bind(this));
    },
    setVars: function() {
      this.setWindowHeight();
      this.setWindowWidth();
    },
    setWindowHeight: function() {
      document.documentElement.style.setProperty('--window-height', `${window.innerHeight}px`);
    },
    setWindowWidth: function() {
      document.documentElement.style.setProperty('--window-width', `${window.innerHeight}px`);
    },
  },
  sra: {
    init(){
      const el = document.querySelector("#footer-nav .logo-list");

      if(el){
        el.insertAdjacentHTML("afterbegin", `
          <li>
              <!-- Start of SRA Digital Badge code -->
              <div style="max-width:275px;max-height:163px; width: 100%;"><div style="position: relative;padding-bottom: 59.1%;height: auto;overflow: hidden;"><iframe frameborder="0" scrolling="no" allowTransparency="true" src="https://cdn.yoshki.com/iframe/55845r.html" style="border:0px; margin:0px; padding:0px; backgroundColor:transparent; top:0px; left:0px; width:100%; height:100%; position: absolute;"></iframe></div></div>
              <!-- End of SRA Digital Badge code -->
          </li>
        `);
      }
    }
  },
  helpers: {
    requireAll: function (r) {
      r.keys().forEach(r);
    },
    initSvg: function () {
      svgxhr({
        filename: './wp-content/themes/class/dist/sprite.svg', //URL to fetch
      });
    }
  }
};

window.namespace.init();


if (typeof NodeList.prototype.forEach !== 'function') {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

// SVG Sprite Loader
window.namespace.helpers.requireAll(require.context('../images/icons/', true, /\.svg$/));
